$(function() {
    $(document).on('click', 'a[data-remote-modal]', function(event) {
        event.preventDefault();
        Modal.showServiceModal(this.href, this.dataset.modalClass, this.dataset.animateClass);
    });

    $(document).on('click', '#service-pills-tab a', function(event) {
        event.preventDefault();
        $(this).tab('show');
    });

    $(document).on('click', '#service-description-read-more', function(event) {
        event.preventDefault();
        $(this).toggleClass('d-none');
        $('#service-desription').removeClass('service-description-overflow');
    });
});

const Modal = {
    showServiceModal: function(url, modal_class, animate_class) {
        Modal.close();
        $('#overlay').fadeIn();

        var tabs = `
          <ul id="service-pills-tab" class="nav nav-pills mb-2 mt-2 small">
            <li class="nav-item">
              <a class="nav-link active" id="pills-information-tab" data-toggle="pill" href="#pills-information">Information</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact">Contact</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="pills-visit-tab" data-toggle="pill" href="#pills-visit">Visit</a>
            </li>
          </ul>`

        var header = '<div class="modal-header"><button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>' + tabs + '</div>';
        var modal_content = $('<div id="service-modal" class="modal fade ' + animate_class + '"><div class="modal-dialog"><div class="modal-content">' + header + '<div class="modal-body clearfix"></div></div></div></div>');

        modal_content.find('.modal-body').load(url, function() {
            $('#overlay').fadeOut();
            modal_content.modal('show');
        });

        if (modal_class) {
            modal_content.find('.modal-dialog').addClass(modal_class);
        }

        modal_content.on('hidden.bs.modal', function() {
            modal_content.remove();
        });

        modal_content.on('shown.bs.modal', function() {
            $('.modal-body form').prepend('<div class=\'error-container\'></div>');
        });

        modal_content.on('shown.bs.modal', function (e) {
            if ($('#service-desription').height() >= 114) {
                $('#service-desription').toggleClass('service-description-overflow');
                $('#service-description-read-more').removeClass('d-none');
            }
        });
    },

    close: function() {
        $('.modal').modal('hide');
    }
};

export { Modal }