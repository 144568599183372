$(document).ready(function() {
  $('.jsRCNArticlesFilter').on('change', function(e) {
    $(".checkboxes").hide();
    $(this).closest('.multiselect').find('select')[0].value = $(this).val();

    handleAgeGroupChange($(this))
    handleLocationChange($(this));
  });
});

function handleLocationChange(element) {
  if(element.attr('name') !== 'location') { return; }

  if(element.val() === "Other location") { 
    $('#jsOtherLocationNotice').removeClass('d-none');
    $('#jsFindSupportBtn').attr('disabled', 'true');
  }else {
    $('#jsOtherLocationNotice').addClass('d-none');
    $('#jsFindSupportBtn').removeAttr('disabled')
  }
}

function handleAgeGroupChange(element) {
  if(element.attr('name') !== 'age_group') { return; }

  $.ajax({
    method: 'get',
    url: '/home/talk_to_professionals/area_of_concerns?child_age_group=' + element.val(),
    dataType: 'Script'
  })
}
