require("@rails/ujs").start();

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require('./jquery.min');
require('./owl.carousel');
require('./custom');
require('./bootstrap.min');
require('../src/trusted-information');
require('../src/service-modal');
require('./chatbot');

$(document).ready(function() {
    $('div.owl-carousel button').first().addClass('active');

    $('.js-peer-btn').on('click', function() {
        $('div.owl-carousel button').removeClass('active');
        $(this).addClass('active');
        var peer_id = $(this).data('peer-id');
        $.ajax({
            url: '/peer_groups/' + peer_id,
            dataType: 'Script',
            method: 'GET'
        });
    });

    $('.js-professional-btn').on('click', function() {
        $('div.owl-carousel button').removeClass('active');
        $(this).addClass('active');
        var professional_id = $(this).data('professional-id');
        $.ajax({
            url: '/admin/service_and_professionals/' + professional_id,
            dataType: 'Script',
            method: 'GET'
        });
    });

    $('.js-resource-btn').on('click', function() {
        $('div.owl-carousel button').removeClass('active');
        $(this).addClass('active');
        var resource_id = $(this).data('resource-id');
        $.ajax({
            url: '/admin/digital_resources/' + resource_id,
            dataType: 'Script',
            method: 'GET'
        });
    });
});

