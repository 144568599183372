$(document).ready(function () {
  $('.chatbot-trigger').on('click', function (event, data) {
    window.fcWidget.open();
  });

  window.widgetAddInterval = setInterval(() => fcWidgetAdded(), 1000);
});

function fcWidgetAdded(){
  if (window.fcWidget !== undefined){
    clearInterval(window.widgetAddInterval)
    window.fcWidget.on("widget:opened", function () {
      gaChatbotEntry();
    });

    clearUserHistroy();
  }
}

function gaChatbotEntry(){
  window.dataLayer.push({
    event: 'chatbot_opened',
    data: {
      page: document.title
    }
  });
  if( window.hj !== undefined ) {
    hj('event', 'chatbot_opened');  
  }
}

function clearUserHistroy() {
  const latestChatbotVersion = document.getElementById('chatbotScript').getAttribute('chatbotVersion');
  const currentChatbotversion = window.localStorage.getItem('ehub-chatbot-version')

  if(latestChatbotVersion !== currentChatbotversion) {
    window.localStorage.setItem('ehub-chatbot-version', latestChatbotVersion)
    
    document.body.style.PointerEvent = 'none';

    window.fcWidget.user.clear().then(function() {
      window.location.reload();
    })
  }
}
