$(document).ready(function() {
    $(".selectBox").click(function() {
        $(".checkboxes").hide();
        $(this).next(".checkboxes").toggle();
        $("body").on("click", function(e) {
            if (!$('.multiselect').has(e.target).length) {
                $(".checkboxes").hide();
            }
        });
    });

    $(".owl-carousel").owlCarousel({
        loop: true,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                slideBy: 1,
                nav: true,
                margin: 8,
                dots: false,
                loop: false,
            },
            768: {
                items: 2,
                slideBy: 2,
                nav: true,
                margin: 24,
                dots: false,
                loop: false,
            },
            1000: {
                items: 3,
                slideBy: 3,
                nav: true,
                margin: 24,
                dots: false,
                loop: false,
            },
            1400: {
                items: 4,
                slideBy: 4,
                nav: true,
                margin: 24,
                dots: false,
                loop: false,
            },
        },
    });

    $(".owl-carousel2").owlCarousel({
        loop: true,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                slideBy: 1,
                nav: true,
                margin: 8,
                dots: false,
                loop: false,
            },
            768: {
                items: 2,
                autoWidth: true,
                slideBy: 2,
                nav: true,
                margin: 24,
                dots: false,
                loop: false,
            },
            1000: {
                items: 3,
                autoWidth: true,
                slideBy: 3,
                nav: true,
                margin: 24,
                dots: false,
                loop: false,
            },
            1400: {
                items: 4,
                slideBy: 4,
                nav: true,
                margin: 24,
                dots: false,
                loop: false,
            },
        },
    });

    const activepage = window.location.pathname;

    if (activepage != '/home' && activepage != '/') {
        const navlinks = document.querySelectorAll('.menu-ha a').forEach(link => {
            if (link.href.includes(activepage)) {
                link.classList.add('active')
            }
        })
    }

    let mybutton = $("#btn-back-to-top");

    $(window).on('scroll', function () {
        scrollFunction();
        quicklinkEvent();
    });

    function scrollFunction() {
        mybutton.toggle($(window).scrollTop() > 20);
    };

    mybutton.on('click', function () {
        $("html, body").animate({ scrollTop: 0 }, "fast");
    });

    $(document).on('click', '.services-load-more-button', function(event) {
        $(this).hide();
        $('#infox-change-search-response-' + event.target.dataset.nextId).show().removeClass('d-none');
        const element = document.getElementById('services-and-professionals');
        element.scrollTop = element.scrollHeight;

        trackLoadMoreGTMEvent();
    });

    function hotjarInitilized() {
        return window.hj !== undefined
    }

    function trackLoadMoreGTMEvent() {
        window.dataLayer.push({
            event: 'services_professional_load_more',
            data: {
                child_age_group: $('.selected-age-group option:selected').val(),
                area_of_concern: $('.selected-topic option:selected').val(),
                location: $('#set-location').val()
            }
        });
        hotjarInitilized() && hj('event', 'services_professional_load_more');
    }

    $(document).on('click', '.article-card-data', function (event) {
        window.dataLayer.push({
            event: 'trusted_information_card',
            data: {
                card_link: $(this).attr('href'),
                card_header: $(this).find('.card-body .card-title').text().trim()
            }
        });
        hotjarInitilized() && hj('event', 'trusted_information_card');
    });
    $(document).on('click', '.service-card-data', function (event) {
        window.dataLayer.push({
            event: 'service_professional_card',
            data: {
                card_title: $(this).find('.card-title').text().trim(),
                card_speciality: $(this).find('.speciality').text().trim(),
                card_location: $(this).find('.location').text().trim()
            }
        });
        hotjarInitilized() && hj('event', 'service_professional_card');
    });

    const quickLink = $('#quick-link');

    quicklinkEvent();

    $('.navbar-toggler').on('click', function () {
        setTimeout(function () {
            quicklinkEvent();
        }, 400);
    });

    function quicklinkEvent() {
        var topHeight = window.matchMedia("(max-width: 767px)").matches ? 5 : 15;
        const headerHeight = $('header').height() + topHeight;
        quickLink.css('top', ($(this).scrollTop() > headerHeight) ? `${topHeight}px` : headerHeight - $(this).scrollTop());
    };
});
